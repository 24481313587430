import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/multiplan/news/:channel/:title/:description/:midia_address",
    name: "MultiplanNewsTemplate",
    component: () => import("@/views/multiplan/news/NewsView.vue"),
  },
  {
    path: "/multiplan/weather/:city_name/:forecast_address",
    name: "MultiplanWeatherTemplate",
    component: () => import("@/views/multiplan/weather/WeatherView.vue"),
  },
  {
    path: "/uol_template/:carouselId/:index",
    name: "UolTemplate",
    component: () => import("@/views/UolTemplateView.vue"),
  },
  {
    path: "/multiplan/uol/:title/:description/:midia_address",
    name: "MultiplanUolTemplate",
    component: () => import("@/views/multiplan/backup/uol/UolTemplateView.vue"),
  },
  {
    path: "/multiplan/canaltech/:title/:description/:midia_address",
    name: "MultiplanCanaltechTemplate",
    component: () =>
      import("@/views/multiplan/backup/canaltech/CanalTechTemplateView.vue"),
  },
  {
    path: "/multiplan/bbc/:title/:description/:midia_address",
    name: "MultiplanBbcTemplate",
    component: () => import("@/views/multiplan/backup/bbc/BbcTemplateView.vue"),
  },
  {
    path: "/multiplan/climatempo/:title/:description/:midia_address",
    name: "MultiplanClimatempoTemplate",
    component: () =>
      import("@/views/multiplan/backup/climatempo/ClimatempoTemplateView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
